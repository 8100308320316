import { graphql, PageProps } from "gatsby"
import React from "react"

import { useCheckAuth } from "src/hooks/useCheckAuth"

import Layout from "../components/Layout/Layout"
import SignIn from "../components/Account/SignIn/SignIn"
import SEO from "../components/seo"

const Login: React.FC<PageProps> = ({ location, data }) => {
  useCheckAuth()
  return (
    <Layout location={location}>
      <SEO title="Login BookAble24" />

      <SignIn />
    </Layout>
  )
}

export default Login

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
